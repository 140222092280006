







import { Component, Vue } from "vue-property-decorator";
// import AccesoPortal from "@/components/Common/AccesoPortal.vue";

@Component({
  components: {}
})
export default class App extends Vue {}
